import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { TopNavItem } from "./top-nav-item";
import { CustomDropdown } from "src/components/inputs";
import { useAuth } from "src/hooks/use-auth";
// User Permissions
import { priceSetupTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { linesheetSettingsTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { clientManagementTomorrowCrewPermission } from "src/guards/role-based-guard";
import { useEffect } from "react";

export const TopNavSection = (props) => {
  const { items = [], pathname } = props;

  const { user } = useAuth();

  const userPermissions = user?.permissions;

  const generatePriceSetupTabsBasedOnUserPermissions = () => {
    // Finance / Operations / Sales Tabs
    const financeTab = { label: "Finance", value: "finance" };
    const operationsTab = {
      label: "Operations",
      value: "operations",
    };
    const salesTab = { label: "Sales", value: "sales" };

    // User Tabs based on Permissions
    const userTabs = [];

    // Tomorrow Crew Admin
    const isAdmin = userPermissions?.find(
      (object) => object.key === priceSetupTomorrowCrewPermissions.admin
    );

    if (isAdmin) {
      userTabs.push(financeTab, operationsTab, salesTab);
      return { userTabs, selectedTab: userTabs[0]?.value };
    }

    // Price Management Admin
    const hasAdminPermission = userPermissions?.find(
      (object) =>
        object.key === priceSetupTomorrowCrewPermissions.priceManagementAdmin
    );

    if (hasAdminPermission) {
      userTabs.push(financeTab, operationsTab, salesTab);
      return { userTabs, selectedTab: userTabs[0]?.value };
    }

    // Price Management Finance
    const hasFinancePermission = userPermissions?.find(
      (object) =>
        object.key === priceSetupTomorrowCrewPermissions.priceManagementFinance
    );

    if (!hasAdminPermission && hasFinancePermission) {
      userTabs.push(financeTab);
    }

    // Price Management Operations
    const hasOperationsPermission = userPermissions?.find(
      (object) =>
        object.key ===
        priceSetupTomorrowCrewPermissions.priceManagementOperations
    );

    if (!hasAdminPermission && hasOperationsPermission) {
      userTabs.push(operationsTab);
    }

    // Price Management Sales
    const hasSalesPermission = userPermissions?.find(
      (object) =>
        object.key === priceSetupTomorrowCrewPermissions.priceManagementSales
    );

    if (!hasAdminPermission && hasSalesPermission) {
      userTabs.push(salesTab);
    }

    return { userTabs, selectedTab: userTabs[0]?.value };
  };

  const generateLinesheetSettingsTabsBasedOnUserPermissions = () => {
    // Blank Template / Zedonk Mappings
    const blankTemplateTab = {
      label: "Blank Template",
      value: "blank-template",
    };
    const zedonkMappingsTab = {
      label: "Zedonk Mappings",
      value: "zedonk-mappings",
    };

    // User Tabs based on Permissions
    const userTabs = [];

    // Tomorrow Crew Admin
    const hasPermissions = userPermissions?.find(
      (object) =>
        object.key === linesheetSettingsTomorrowCrewPermissions.admin ||
        object.key ===
          linesheetSettingsTomorrowCrewPermissions.priceManagementAdmin
    );

    if (hasPermissions) {
      userTabs.push(blankTemplateTab, zedonkMappingsTab);
      return { userTabs, selectedTab: userTabs[0]?.value };
    }

    return userTabs;
  };

  const generateClientManagementTabsBasedOnUserPermissions = () => {
    const customersTab = {
      label: "Companies",
      value: "companies",
    };
    const contactsTab = {
      label: "Contacts",
      value: "contacts",
    };

    // User Tabs based on Permissions
    const userTabs = [];

    // Tomorrow Crew Admin
    const hasPermissions = userPermissions?.find(
      (object) =>
        object.key === clientManagementTomorrowCrewPermission.admin ||
        object.key === clientManagementTomorrowCrewPermission.clientManagement
    );

    if (hasPermissions) {
      userTabs.push(customersTab, contactsTab);
      return { userTabs, selectedTab: userTabs[0]?.value };
    }

    return userTabs;
  };

  // Tabs Settings
  const priceSetupTabsSettings = generatePriceSetupTabsBasedOnUserPermissions();
  const priceSetupTabs = priceSetupTabsSettings.userTabs;

  const priceSetupElements = priceSetupTabs?.map((tab) => {
    return {
      id: tab.value,
      type: "button",
      label: tab.label,
      handler: null,
      href: `price-setup/${tab.label.toLowerCase()}`,
    };
  });

  const linesheetSettingsTabsSettings =
    generateLinesheetSettingsTabsBasedOnUserPermissions();
  const linesheetSettingsTabs = linesheetSettingsTabsSettings.userTabs;

  const linesheetSettingsElements = linesheetSettingsTabs?.map((tab) => {
    return {
      id: tab.value,
      type: "button",
      label: tab.label,
      handler: null,
      href: `linesheet-settings/${tab.value}`,
    };
  });

  const clientManagementTabsSettings =
    generateClientManagementTabsBasedOnUserPermissions();
  const clientManagementTabs = clientManagementTabsSettings.userTabs;

  const clientManagementElements = clientManagementTabs?.map((tab) => {
    return {
      id: tab.value,
      type: "button",
      label: tab.label,
      handler: null,
      href: `client-management/${tab.value}`,
    };
  });

  return (
    <Stack
      component="ul"
      direction="row"
      spacing={1}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
        margin: "0 25px",
      }}
    >
      {items.map((item) => {
        const checkPath = !!(item.path && pathname);
        const partialMatch = checkPath ? pathname.includes(item.path) : false;
        const exactMatch = checkPath ? pathname === item.path : false;

        // Branch

        if (item.items) {
          return (
            <TopNavItem
              active={partialMatch}
              disabled={item.disabled}
              icon={item.icon}
              items={item.items}
              key={item.title}
              label={item.label}
              title={item.title}
            />
          );
        }

        // Leaf
        if (item.title === "Price Setup") {
          return (
            <CustomDropdown
              key={item.title}
              buttons={priceSetupElements}
              label="PRICE SETUP"
              style={{
                width: "170px",
                margin: "0 10px 0 0",
                backgroundColor: partialMatch ? "#E6E6E6" : "white",
              }}
              dropdownStyle={{ width: "170px" }}
              router
            />
          );
        }
        if (item.title === "Client Management") {
          return (
            <CustomDropdown
              key={item.title}
              buttons={clientManagementElements}
              label="CLIENT MANAGEMENT"
              style={{
                width: "230px",
                margin: "0 10px 0 0",
                backgroundColor: partialMatch ? "#E6E6E6" : "white",
              }}
              dropdownStyle={{ width: "230px" }}
              router
            />
          );
        }
        if (item.title === "Linesheet Settings") {
          return (
            <CustomDropdown
              key={item.title}
              buttons={linesheetSettingsElements}
              label="LINESHEET SETTINGS"
              style={{
                width: "230px",
                backgroundColor: partialMatch ? "#E6E6E6" : "white",
              }}
              dropdownStyle={{ width: "230px" }}
              router
            />
          );
        } else {
          return (
            <TopNavItem
              active={partialMatch}
              disabled={item.disabled}
              external={item.external}
              key={item.title}
              label={item.label}
              path={item.path}
              title={item.title}
            />
          );
        }
      })}
    </Stack>
  );
};

TopNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
};

import { createSlice } from "@reduxjs/toolkit";

const eTafState = {
  selectedCompany: "",
  currentCompanyName: "",
  companyData: null,
  companyDataLoading: false,
  companyHash: "",
  dataSubmitted: false,
  allCountries: [],
  currentCountryProvinces: [],
  companyDataSubmitting: false,
  companyDataFromStrapi: null,
  dataIsFromStrapi: false,
  submitError: false,
  customers: [],
  customersLoading: true,
  eTafData: {},
  eTafDataLoading: false,
  approvingETaf: [],
  approvingETafModalVisible: false,
  contacts: [],
  contactsLoading: false,
  contactsOfCompany: [],
  currentContactDetails: null,
  vatAvailabilityError: false,
  existingVAT: null,
  inputAddress: "",
  addressPredictions: [],
  addressData: null,
  associationInfo: { open: false, companyName: "" },
  associationRequestsOfCompany: [],
  bulkEmailsBackgroundTaskStatus: "",
};

const reducers = {
  setSelectedCompany(state, action) {
    state.selectedCompany = action.payload;
  },
  setCurrentCompanyName(state, action) {
    state.currentCompanyName = action.payload;
  },
  setCompanyData(state, action) {
    state.companyData = action.payload;
  },
  setCompanyDataFromStrapi(state, action) {
    state.companyDataFromStrapi = action.payload;
  },
  setCompanyDataLoading(state, action) {
    state.companyDataLoading = action.payload;
  },
  setCompanyHash(state, action) {
    state.companyHash = action.payload;
  },
  setDataSubmitted(state, action) {
    state.dataSubmitted = action.payload;
  },
  setAllCountries(state, action) {
    state.allCountries = action.payload;
  },
  setProvinces(state, action) {
    state.currentCountryProvinces = action.payload;
  },
  setCompanyDataSubmitting(state, action) {
    state.companyDataSubmitting = action.payload;
  },
  setDataIsFromStrapi(state, action) {
    state.dataIsFromStrapi = action.payload;
  },
  setSubmitError(state, action) {
    state.submitError = action.payload;
  },
  setCustomers(state, action) {
    state.customers = action.payload;
  },
  setCustomersLoading(state, action) {
    state.customersLoading = action.payload;
  },
  setETafData(state, action) {
    state.eTafData = action.payload;
  },
  setETafDataLoading(state, action) {
    state.eTafDataLoading = action.payload;
  },
  setApprovingETaf(state, action) {
    state.approvingETaf = action.payload;
  },
  setApprovingETafModalVisible(state, action) {
    state.approvingETafModalVisible = action.payload;
  },
  setContacts(state, action) {
    state.contacts = action.payload;
  },
  setContactsLoading(state, action) {
    state.contactsLoading = action.payload;
  },
  setContactsOfCompany(state, action) {
    state.contactsOfCompany = action.payload;
  },
  setCurrentContactDetails(state, action) {
    state.currentContactDetails = action.payload;
  },
  setExistingVAT(state, action) {
    state.existingVAT = action.payload;
  },
  setVatAvailabilityError(state, action) {
    state.vatAvailabilityError = action.payload;
  },
  setInputAddress(state, action) {
    state.inputAddress = action.payload;
  },
  setAddressPredictions(state, action) {
    state.addressPredictions = action.payload;
  },
  setAddressData(state, action) {
    state.addressData = action.payload;
  },
  setAssociationInfo(state, action) {
    state.associationInfo = action.payload;
  },
  setAssociationRequestsOfCompany(state, action) {
    state.associationRequestsOfCompany = action.payload;
  },
  setBulkEmailsBackgroundTaskStatus(state, action) {
    state.bulkEmailsBackgroundTaskStatus = action.payload;
  },
};

export const slice = createSlice({
  name: "eTaf",
  initialState: eTafState,
  reducers,
});

export const { reducer } = slice;

import { useState, useContext, useEffect } from "react";
import { Box, Popover, MenuItem, Alert, Typography } from "@mui/material";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AlertContext } from "src/components/alert/AlertContext";
import { useAuth } from "src/hooks/use-auth";
import { emailServiceThunks } from "src/thunks/email-service";
import { useDispatch, useSelector } from "src/store";
import {
  CustomButton,
  CustomTextField,
  CustomTypography,
} from "src/components/inputs";
// Redux Toolkit
import { thunks } from "src/thunks/linesheet";

export const HelpModal = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const categoryOptions = [
    "Linesheets issue",
    "General question",
    "Request for Linesheet to be moved back to Draft",
  ];
  const { alert, showAlert, hideAlert } = useContext(AlertContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();
  const groupName = user?.group?.groupName;
  const { opsSupportUser, linesheets } = useSelector(
    (state) => state.linesheet
  );
  const dispatch = useDispatch();

  const initialValues = {
    category: "",
    linesheet: "",
    subject: "",
    message: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        helpers.resetForm();
        setIsSubmitting(true);
        showAlert(
          "Thank you for submitting your request, we'll get back to you!",
          "success"
        );
        // Call thunk to send question email
        dispatch(
          emailServiceThunks.sendQuestionEmail(user, {
            category: values.category,
            linesheet: values.linesheet,
            subject: values.subject,
            message: values.message,
          })
        );
        setTimeout(() => {
          hideAlert();
          onClose();
          setIsSubmitting(false);
        }, 3000);
      } catch (err) {
        toast.error("Something went wrong!");
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        hideAlert();
        onClose();
        setIsSubmitting(false);
      }
    },
  });

  const tmwTextStyle = {
    letterSpacing: 1,
    fontSize: 13,
    fontWeight: 600,
  };

  useEffect(() => {
    // Fetch the name of OPS Support user of the current user's brand
    if (user?.brand) {
      dispatch(thunks.getOPSSupportUser(user?.brand.name));
    }
  }, []);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{
        sx: {
          width: 700,
          p: 1,
          border: "1px solid #e6e6e6",
          borderRadius: 0,
          boxShadow: "none",
        },
      }}
      {...other}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2 }} style={tmwTextStyle}>
          <div style={{ marginBottom: "25px" }}>
            <CustomTypography
              title
              message="Contact us"
              style={{
                fontSize: 18,
              }}
            />
          </div>
          <Typography
            style={{
              fontFamily: "tmwCustomFont",
              textTransform: "uppercase",
              letterSpacing: 2,
              fontSize: 11,
              wordWrap: "break-word",
            }}
          >
            Please write here your request and{" "}
            {opsSupportUser &&
            opsSupportUser.firstName &&
            opsSupportUser.lastName ? (
              <strong>
                {opsSupportUser?.firstName} {opsSupportUser?.lastName}
              </strong>
            ) : (
              "we"
            )}{" "}
            will get back to you as soon as possible.
          </Typography>
          <CustomTextField
            label="Category"
            name="Category"
            value={formik.values.category}
            handleChange={(e) =>
              formik.setFieldValue("category", e.target.value)
            }
            required
            style={{ padding: "15px 0", marginTop: "25px" }}
            select
          >
            {categoryOptions?.map((option) => (
              <MenuItem
                key={option}
                value={option}
                style={{
                  ...tmwTextStyle,
                  fontFamily: "tmwCustomFont",
                  fontWeight: 500,
                }}
              >
                {option}
              </MenuItem>
            ))}
          </CustomTextField>
          {groupName === "Brand" &&
            (formik.values.category === "Linesheets issue" ||
              formik.values.category ===
                "Request for Linesheet to be moved back to Draft") && (
              <CustomTextField
                label="Linesheet"
                name="Linesheet"
                value={formik.values.linesheet}
                handleChange={(e) =>
                  formik.setFieldValue("linesheet", e.target.value)
                }
                style={{ padding: "15px 0" }}
                select
              >
                {linesheets?.map((linesheet) => (
                  <MenuItem
                    key={linesheet.name}
                    value={linesheet.name}
                    style={tmwTextStyle}
                  >
                    {linesheet.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          <CustomTextField
            label="Subject"
            name="Subject"
            value={formik.values.subject}
            handleChange={(e) =>
              formik.setFieldValue("subject", e.target.value)
            }
            rows={3}
            required
            style={{ padding: "15px 0" }}
          />
          <CustomTextField
            label="Message"
            name="Message"
            value={formik.values.message}
            handleChange={(e) =>
              formik.setFieldValue("message", e.target.value)
            }
            rows={3}
            required
            style={{ padding: "15px 0" }}
          />
          <CustomButton
            fullSize
            label="Send"
            disabled={isSubmitting}
            style={{ marginTop: "25px" }}
            type="submit"
          />
          {alert && (
            <Alert
              severity={alert.severity}
              sx={{
                fontFamily: "tmwCustomFont",
                fontSize: 11,
                letterSpacing: 1,
                textTransform: "uppercase",
                marginTop: 2,
              }}
            >
              {alert.message}
            </Alert>
          )}
        </Box>
      </form>
    </Popover>
  );
};

import { lazy } from "react";

const Index = lazy(() => import("src/pages/customers"));
const CompanySelect = lazy(() =>
  import("src/pages/dashboard/company/forms/company-select")
);
const CompanyForm = lazy(() =>
  import("src/pages/dashboard/company/forms/company-form")
);

export const customersRoutes = [
  {
    path: "customers",
    children: [
      {
        path: "/customers/dashboard/:company_hash",
        element: <Index />,
      },
      {
        path: "/customers/company-select",
        element: <CompanySelect />,
      },
      {
        path: "/customers/company-form/:company_hash",
        element: <CompanyForm />,
      },
    ],
  },
];

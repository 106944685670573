import axios from "axios";

const deployEnvironment = require("../../deployEnvironment.js");
const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class ETafAPI {
  async getCompanyData(companyName) {
    if (!companyName) return false;

    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getCompanyData`,
      {
        companyName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const companyData = response.data.companyData;

    return new Promise((resolve, reject) => {
      try {
        if (!companyData) {
          reject(new Error("Unauthorized"));
          return;
        }
        resolve(companyData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getCompanyDataFromStrapi(companyName) {
    if (!companyName) return false;

    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getCompanyDataFromStrapi`,
      {
        companyName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const companyData = response.data.companyData;

    return new Promise((resolve, reject) => {
      try {
        if (!companyData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(companyData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getCompanyHash(companyName) {
    if (!companyName) return false;

    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getCompanyHash`,
      {
        companyName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const companyHash = response.data.companyHash;

    return new Promise((resolve, reject) => {
      try {
        if (!companyHash) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(companyHash);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async verifyCompanyHash(userHash, companyHash) {
    if (!companyHash) return false;

    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/verifyCompanyHash`,
      {
        userHash,
        companyHash,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const company = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!company) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(company);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getCompanyName(userHash, companyHash) {
    try {
      if (!companyHash) return false;

      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      const response = await axios.post(
        `${API}/api/getCompanyName`,
        {
          userHash,
          companyHash,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const company = response.data;

      return new Promise((resolve, reject) => {
        try {
          if (!company) {
            reject(new Error("Unauthorized"));
            return;
          }

          resolve(company);
        } catch (err) {
          reject(new Error("Internal server error"));
        }
      });
    } catch (err) {
      // If user has a pending association with the current company, redirect to an info page
      if (
        err?.response?.data?.error?.message.split(/,(.*)/s)[0] ===
        "Pending association"
      ) {
        return {
          association: true,
          companyName: err?.response?.data?.error?.message.split(/,(.*)/s)[1],
        };
      } else {
        window.location.href = "/404";
      }
    }
  }
  async submitCompanyData(
    userHash,
    companyName,
    data,
    companyHash,
    isAdmin,
    newCustomer
  ) {
    if (!data) return false;

    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/submitCompanyData`,
      {
        userHash,
        companyName,
        data,
        companyHash,
        isAdmin,
        newCustomer,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async getCompanyReferences(userHash, companyName) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getCompanyReferences`,
      { userHash, companyName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const companyReferences = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!companyReferences) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(companyReferences);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getProvinces(userHash, country) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getProvinces`,
      { userHash, country },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const provinces = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!provinces) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(provinces);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getEtafCustomers() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.get(`${API}/api/getEtafCustomers`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const customers = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!customers) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(customers);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getEtafData(customerId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getEtafData`,
      { customerId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const eTafData = response.data;

    return eTafData;
  }
  async approveETaf(userHash, customerId) {
    if (!userHash || !customerId) return false;

    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/approveETaf`,
      {
        userHash,
        customerId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async getContact(contactId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getContact`,
      { contactId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const contact = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!contact) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(contact);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getContacts() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.get(`${API}/api/getContacts`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const contacts = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!contacts) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(contacts);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getContactsOfCompany(companyId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getContactsOfCompany`,
      { companyId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const contactsOfCompany = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!contactsOfCompany) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(contactsOfCompany);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async sendInitialEmailToContact(email, isNewContact) {
    if (!email) return false;

    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/sendInitialEmailToContact`,
      { email, isNewContact },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async checkVatAvailability(vatCode, customerId, contactId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/checkVatAvailability`,
      { vatCode, customerId, contactId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const foundVatCode = response.data;

    return foundVatCode;
  }
  async getAddressPredictions(address) {
    const response = await axios.post(`${API}/api/getAddressPredictions`, {
      address,
    });

    const addressPredictions = response.data;

    return addressPredictions;
  }
  async getAddressData(placeId) {
    const response = await axios.post(`${API}/api/getAddressData`, {
      placeId,
    });

    const addressData = response.data;

    return addressData;
  }
  async sendAssociationRequest(userId, companyId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/sendAssociationRequest`,
      {
        userId,
        companyId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const associationRequest = response.data;

    return associationRequest;
  }
  async getAssociationRequests(companyId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/getAssociationRequests`,
      {
        companyId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const associationRequests = response.data;

    return associationRequests;
  }
  async confirmAssociationRequest(companyId, contactId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/confirmAssociationRequest`,
      {
        companyId,
        contactId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const associationRequest = response.data;

    return associationRequest;
  }
  async removeAssociationRequest(companyId, contactId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/removeAssociationRequest`,
      {
        companyId,
        contactId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const associationRequest = response.data;

    return associationRequest;
  }
  async sendBulkInitialEmailsToContacts() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.get(`${API}/api/sendBulkInitialEmailsToContacts`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export const eTafApi = new ETafAPI();
